import { axios } from '@/utils/request'

export function getSermons(parameter) {
    return axios({
        url: 'sermons',
        method: 'get',
        params: parameter
    })
}

export function exportSermons(parameter) {
    return axios({
        url: 'sermons/export',
        method: 'get',
        params: parameter
    })
}

export function deleteSermons(sermonId) {
    return axios({
        url: `sermons/${sermonId}`,
        method: 'delete'
    })
}

export function storeSermons(parameter) {
    return axios({
        url: 'sermons',
        method: 'post',
        data: parameter
    })
}

export function updateSermons(sermonId, parameter) {
    return axios({
        url: `sermons/${sermonId}`,
        method: 'put',
        data: parameter
    })
}

export function getSermon(sermonId) {
    return axios({
        url: `sermons/${sermonId}`,
        method: 'get'
    })
}

