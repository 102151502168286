<template>
    <van-form @submit="onSubmit" validate-first>
        <van-field
            v-model="form.title"
            name="title"
            label="标题"
            placeholder="请输入讲章标题"
            required
            :rules="[{ required: true, message: '请输入讲章标题' }]"
            clearable
        ></van-field>
        <van-field
            readonly
            clickable
            name="calendar"
            v-model="form.speech_at"
            label="时间"
            placeholder="请选择时间"
            @click="showCalendar = true"
            required
            :rules="[{ required: true, message: '请选择时间' }]"
            clearable
        ></van-field>
        <van-calendar v-model="showCalendar" :min-date="minDate" @confirm="onSpeechConfirm"></van-calendar>
        <van-field
            v-model="form.location"
            name="location"
            label="地点"
            placeholder="请输入地点"
            required
            :rules="[{ required: true, message: '请输入讲道地点' }]"
            clearable
        ></van-field>
<!--        <van-field-->
<!--            v-model="form.author"-->
<!--            name="author"-->
<!--            label="作者"-->
<!--            placeholder="请输入作者"-->
<!--            required-->
<!--            :rules="[{ required: true, message: '请输入作者' }]"-->
<!--        ></van-field>-->
        <van-field
            readonly
            clickable
            name="category_id"
            :value="sCatName"
            label="分类"
            placeholder="请选择分类"
            @click="showPicker = true"
            required
            :rules="[{ required: true, message: '请选择分类' }]"
            clearable
        ></van-field>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onCategoryConfirm"
                @cancel="showPicker = false"
                :default-index="defaultIndex"
            ></van-picker>
        </van-popup>
        <van-field
            v-model="form.backup"
            rows="3"
            autosize
            label="备注"
            type="textarea"
            maxlength="200"
            placeholder="请输入备注"
            show-word-limit
            clearable
        ></van-field>
        <div style="margin: 16px;">
            <van-button
                round
                block
                type="info"
                native-type="submit"
                :loading="submitting"
                loading-size="16px"
                loading-text="提交中..."
                :disabled="submitting"
            >提交</van-button>
        </div>
    </van-form>
</template>

<script>
import { storeSermons, updateSermons } from '@/api/sermon'
import { getCategories } from '@/api/category'
import { LOGIN_USER } from '@/utils/constants'

export default {
    name: 'SermonForm',
    props: {
        sermon: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            minDate: new Date(2000, 0, 1),
            defaultIndex: 0,
            submitting: false,
            showPicker: false,
            showCalendar: false,
            form: {
                title: null,
                location: null,
                author: null,
                speech_at: null,
                category_id: null,
                backup: ''
            },
            categories: [],
            sCatName: null
        }
    },
    computed: {
        columns () {
            const need = []
            this.categories.map(cat => need.push(cat.name))
            return need
        }
    },
    methods: {
        onCategoryConfirm(value, index) {
            this.form.category_id = this.categories[index].id
            this.sCatName = this.categories[index].name
            this.showPicker = false
        },
        onSpeechConfirm(date) {
            const m = date.getMonth() + 1
            const d = date.getDate()
            this.form.speech_at = `${date.getFullYear()}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
            this.showCalendar = false
        },
        onSubmit() {
            this.submitting = true
            let then = null
            if (this.sermon) {
                then = updateSermons(this.sermon.id, this.form)
            } else {
                then = storeSermons(this.form)
            }
            then.then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.$emit('ok')
            }).finally(() => {
                this.submitting = false
            })
        }
    },
    created() {
        const user = this.$ls.get(LOGIN_USER)
        this.form.author = user.nickname
        if (this.sermon) {
            this.form = this.sermon
            this.form.backup = this.form.backup === null ? '' : this.form.backup
            this.form.category_id = this.sermon.category.id
        }
        getCategories({ type: 'sermon' }).then(res => {
            this.categories = res.data
            if (this.sermon) {
                this.categories.map((cat, i) => {
                    if (this.form.category_id === cat.id) {
                        this.defaultIndex = i
                        this.sCatName = cat.name
                    }
                })
            }
        })
    }
};
</script>

<style lang="less">
    .action-icon {
        margin-left: 5px;
    }
</style>
