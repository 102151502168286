<template>
    <sermon-form @ok="handleOk"></sermon-form>
</template>

<script>
import SermonForm from './SermonForm'

export default {
    name: 'SermonCreate',
    components: { SermonForm },
    data () {
        return {}
    },
    methods: {
        handleOk () {
            setTimeout(() => {
                this.$router.push({ path: '/sermon' })
            }, 1000)
        }
    }
};
</script>

<style lang="less">

</style>
